import { internal, registerInput } from 'okta'

const TextBox = internal.views.forms.inputs.TextBox;
const TYPE = 'sapId';
const ERROR_SAPID_MAP = [
  'model.validation.field.sapId.invalidNumber',
];

export const SapInput = TextBox.extend({
  constructor() {
    TextBox.apply(this, arguments);
    const props = this.model.__schema__.props[this.options.name] || {};
    props.validate = this.validationDelegate.bind(this);
  },

  // /**
  //  * @Override
  //  */
  // events: {
  //   'input input': 'update',
  //   'change input': 'update',

  //   'keyup input': function keyupInput(e) {
  //     if (internal.util.Keys.isEsc(e)) {
  //       this.model.trigger('form:cancel');
  //     }
  //   }
  // },

  /**
   * @Override
   */
  postRender() {
    TextBox.prototype.postRender.call(this);
    const params = this.options.params;
    const inputElement = this.$(`input[name="${this.options.name}"]`);
  },

  /**
   * @Override
   */
  val() {
    return this.$('input').val();
  },

  /**
   * 
   * @param {string} value 
   * @returns {string | boolean} 
   */
  validationDelegate(value) {
    //start with 0 length 10 or start with 1 then lenght will be 9 ,only numeric
    var sapIdRegex = /^0\d{9}$|^[1-9]\d{8}$$/;
    if(!sapIdRegex.test(value)){ 
      return ERROR_SAPID_MAP[0];
    }
    return true;
  }
});

registerInput(TYPE, SapInput);
