import { _, internal, Input, registerInput, loc, $ } from 'okta'
import { K, template } from 'handlebars';
import Util from "../../util/Util";
const TextBox = internal.views.forms.inputs.TextBox;
const TYPE = 'Consent';
const HOST_CLASS_NAME = 'okta-form-input-field';
let values = {};
const RolesList = 
   {
    "pnp":{
    "text":"I have read privacy & policy",
    "links":[{"href":"https://www.google.com", "word":"privacy & policy"},],
    "required":false,
    "default":true,
    "visibility":false,
    
   },
   "tnc":{
    "text":"I have read terms & conditions",
    "links":[{"href":"https://www.google.com", "word":"terms"},
            {"href":"https://www.bing.com", "word":"conditions"}],
    "required":false,
    "default":true,
    "visibility":false,
   },
} 

;

function lookupPropertyDefaultFn(parent, propertyName) {
  if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
    return parent[propertyName];
  }
  return undefined;
};
export const ConsentInput = TextBox.extend({
  constructor() {
    TextBox.apply(this, arguments);
    const props = this.model.__schema__.props[this.options.name] || {};
    props.validate = this.validate.bind(this);
  },
  template: template({
    'compiler': [8, '>= 4.3.0'],
    'main': function main(_container, depth0, _helpers, _partials, _data) {
       
      const lookupProperty = _container.lookupProperty || lookupPropertyDefaultFn;
      const inputId = lookupProperty(depth0, 'inputId') || '';
      const name = lookupProperty(depth0, 'name') || '';
      const listObj = lookupProperty(depth0, 'params') || '';
      const placeholder = lookupProperty(depth0, 'placeholder') || '';
      const props = depth0.model.__schema__.props[depth0.name];
      const list = depth0.model.__schema__.props[depth0.params];
      const required = lookupProperty(depth0, 'required') || props && lookupProperty(props, 'required') || false;
      
      var joined =(Object.values(listObj).join(''));

      try {
          joined = JSON.parse(joined);
      } 
      catch(e) {
          Util.logConsoleError(e); // error in the above string (in this case, yes)!
      }
      for(var key in RolesList){
        if(key in joined){
          for(var k in RolesList[key]){
            if(k in joined[key]){
               // joined[key][k]=RolesList[key][k]
            }else{
              joined[key][k]=RolesList[key][k];
            }
          }
        }
        
      }
      
      var html=  "";
      for (var key in joined) {
        var typeTile=""; 
        Util.logConsoleError("Keys =>"+key);
        if(key.indexOf("label")!==-1){
        html+=`<div data-se="o-form-label" class="okta-form-label o-form-label"><label for="input163">${joined[key]}&nbsp;</label></div><br>`;
      Util.logConsoleError("in label");
        
      }else{
      Util.logConsoleError("in consent");

        if(joined[key]["visibility"]){
          var label=`<label for="`+ key+`">${joined[key]["text"]}</label>`,checkbox="";
          
          if(joined[key]["required"]){
            label=`<label for="`+ key+`">${joined[key]["text"]}<sup>*</sup></label>`;
          }
          else{
            label=`<label for="`+ key+`">${joined[key]["text"]}</label>`;  
          }
          if(joined[key]["default"]){
            checkbox=`<input type="checkbox" name="${name}" data-req="${joined[key]["required"]}" id="${key}" data-val="${key}" value="${key}" checked="checked">`;
            values[key] = true; //set default values
          }
          else{
            checkbox=`<input type="checkbox" name="${name}" data-req="${joined[key]["required"]}"  id="${key}" data-val="${key}" value="${key}">`;
            values[key] = false; //set default values
          }

          joined[key]["links"].forEach((link) => {
            label= label.replace(link["word"],`<a href="${link["href"]}" target="_blank">${link["word"]}</a>`)
          });

          html+=`<div class="alignLabel" id=${key}_div>${checkbox} ${label}</div>`;
        }
      }
      }
   
      return html;
    },

    'useData': true
  }),
  /**
   * @Override
   */
  events: {
    'input input': 'update',
    'change input': 'update',
    'keydown input': 'update',
    'keyup input': function keyupInput(e) {
      if (internal.util.Keys.isEnter(e)) {
        this.model.trigger('form:save');
      }
      else if (internal.util.Keys.isEsc(e)) {
        this.model.trigger('form:cancel');
      }
    }
  },

  update(e){

    this._clearErrors();

    Input.prototype.update.call(this);
  },

  /**
   * @Override
   */
  postRender() {
    TextBox.prototype.postRender.call(this);
    this.model.set(this.options.name, JSON.stringify(values));

    this.$el.removeClass('input-fix');
    this.$el.addClass('Consent');
  },

 /**
   * @Override
   */
  val() {
    Util.logConsoleError(this._getValues());
    return JSON.stringify(this._getValues());
  },

  /**
   * Validate the input to the consent field
   * 
   * @param {jQuery.Event} event
   * @returns {string | boolean}
   */
  validate(event) {
    const self = this;

    let isValid = true;

    this._clearErrors();

    values = this._getValues();
    Util.logConsoleError(JSON.stringify(values));

    _.each(values, function(value, key){
      if(!value.val && values[key].required){
        self._showBlankError(key);
        isValid = false;
      }
    });
    
    return isValid; 
  },


  /**
   * Display the 'model.validation.field.blank' error for the given checkbox
   *  
   * @param {string} checkbox_key
   **/
  _showBlankError(checkbox_key){

    //let error_txt = loc('model.validation.field.blank', 'login');
    let error_txt = "Please accept to continue";
    let error_span = '<span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span>';
    let error_msg = `<p id="checkbox_required_error" name="checkbox_required_error" class="okta-form-input-error o-form-input-error o-form-explain" role="alert">${error_span}${error_txt}</p>`;

    const ele = $(`#${checkbox_key}_div`);
    this.$el.addClass('o-form-has-errors');
    ele.after(error_msg);

    this.$el.attr('aria-invalid', 'true');
    this.$el.attr('aria-describedby', 'checkbox_required_error');
  },


    /**
     * Remove all errors present on the consent input
     **/
  _clearErrors(){
    let errors = $('[name="checkbox_required_error"]');

    this.$el.removeClass('o-form-has-errors');
    _.each(errors, function(error){
      error.remove();
    });
  },


  /**
   * Get the values of all checkboxes
   * @returns {Object} values
   **/
  _getValues(){
    let checkboxes = $(`[name="${this.options.name}"]`);
    
    // looping through all checkboxes
    for (let i = 0; i < checkboxes.length; i++) {
      //Util.logConsoleError(checkboxes[i].id);
      //Util.logConsoleError(checkboxes[i].getAttribute("data-req"));
      values[(checkboxes[i].id)]={};
      values[checkboxes[i].id]["val"] = checkboxes[i].checked;
     values[checkboxes[i].id]["required"] = checkboxes[i].getAttribute("data-req")=="true"?true:false;
    }
    Util.logConsoleError("in loop "+JSON.stringify(values));
    return values;
  }

});

registerInput(TYPE, ConsentInput);

